import { Avatar, Tooltip } from '@kaya/kaya-ui-design-system-pb';
import { useChatHeader, useMobile } from '../../../../../hooks';
import { CN, truncateWithEllipsis, uniqueId } from '../../../../../utils';
import { IKplInfoChips, KplInfoChips } from './common/KplInfoChips';
import { useCustomKplContext } from '../../../../../contexts';
import { useEffect, useState } from 'react';

export const CustomKplChatHeader = () => {
    const { headerMetaDataList, name, isKplDataLoading, isKplLoading } = useCustomKplContext();

    const lists: IKplInfoChips[] = [
        {
            icon: 'ri-calendar-schedule-line',
            label:
                headerMetaDataList?.remainingDays !== undefined
                    ? `${headerMetaDataList?.remainingDays} day${headerMetaDataList?.remainingDays > 1 ? 's' : ''} remaining`
                    : 'Loading data...',
        },
        {
            icon: 'ri-copper-coin-line',
            label:
                headerMetaDataList?.tokenUsage !== undefined &&
                headerMetaDataList?.metaDataConfigs?.GUEST_USER_MAX_TOKEN_COUNT !== undefined
                    ? `${headerMetaDataList?.tokenUsage}/${headerMetaDataList?.metaDataConfigs?.GUEST_USER_MAX_TOKEN_COUNT} tokens used`
                    : 'Loading data...',
        },
        {
            icon: 'ri-article-line',
            label:
                headerMetaDataList?.totalDocumentSize !== undefined &&
                headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_SIZE_LIMIT !== undefined
                    ? `${(headerMetaDataList?.totalDocumentSize / (1024 * 1024)).toFixed(2)}/${headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_SIZE_LIMIT}MB knowledge documents used`
                    : 'Loading data...',
        },
        {
            icon: 'ri-file-upload-line',
            label:
                headerMetaDataList?.totalDocumentCount !== undefined &&
                headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_COUNT_LIMIT !== undefined
                    ? `${headerMetaDataList?.totalDocumentCount}/${headerMetaDataList?.metaDataConfigs?.GUEST_DOCUMENT_COUNT_LIMIT} document${headerMetaDataList?.totalDocumentCount > 1 ? 's' : ''} ingested`
                    : 'Loading data...',
        },
    ];

    const { currentModelImage, description, label } = useChatHeader();
    const isMobile = useMobile();
    const [titleText, setTitleText] = useState<string>('Custom KPL');

    useEffect(() => {
        !isKplDataLoading && setTitleText(headerMetaDataList?.isGuestKPLCreated ? (name ? name : label) : 'Custom KPL');
    }, [headerMetaDataList, label, isKplDataLoading, isKplLoading]);

    return (
        <>
            <div className="flex items-center justify-between sm:w-full w-full">
                <div className="flex items-center gap-x-2 sm:items-center">
                    <div>
                        <Avatar
                            shape="square"
                            imageURL={currentModelImage}
                            type="image"
                            isLoading={isKplDataLoading}
                            size={isMobile ? 'md' : 'lg'}
                        />
                    </div>
                    <div
                        className={CN('flex flex-col', {
                            '!flex-col items-start gap-x-2': isMobile,
                        })}
                    >
                        {isKplDataLoading ? (
                            <h6 className="text-N-600 font-500 text-lg sm:text-md ms-0 ps-0" title={'Loading...'}>
                                Loading...
                            </h6>
                        ) : (
                            <h6
                                className="text-N-600 font-500 text-lg sm:text-md ms-0 ps-0"
                                title={truncateWithEllipsis(titleText, isMobile ? 25 : 80)}
                            >
                                {truncateWithEllipsis(titleText, isMobile ? 25 : 80)}
                            </h6>
                        )}

                        <div className="flex gap-2 items-center">
                            <div className="flex items-center flex-wrap gap-2">
                                <div className="flex gap-2 flex-wrap items-center">
                                    <div className="relative flex gap-x-1 justify-center items-center cursor-default py-[1px] px-2 rounded bg-B-100">
                                        <p className="text-xs text-B-700 relative top-[1px] text-nowrap">
                                            {isKplDataLoading ? (
                                                <span>Loading...</span>
                                            ) : (
                                                truncateWithEllipsis(String(titleText), isMobile ? 20 : 100)
                                            )}
                                        </p>
                                        <Tooltip
                                            id={uniqueId()}
                                            place="bottom"
                                            subText={description}
                                            variant="dark"
                                            tooltipType="detailed"
                                        >
                                            <i className="ri-question-line text-sm text-B-700 relative" />
                                        </Tooltip>
                                    </div>
                                    {!isMobile && lists?.map((list, i) => <KplInfoChips list={list} key={i} />)}

                                    {isMobile && (
                                        <Tooltip
                                            id={uniqueId()}
                                            place="bottom"
                                            className={isMobile ? '!pe-72' : ''}
                                            subText={
                                                <div className="flex flex-col gap-y-2">
                                                    {lists?.map((list, i) => <KplInfoChips list={list} key={i} />)}
                                                </div>
                                            }
                                            variant="dark"
                                            tooltipType="detailed"
                                        >
                                            <i className="ri-information-2-line text-md text-B-700 relative" />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
