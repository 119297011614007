import { useEffect, useRef, useState } from 'react';
import { useChatContext, useCustomKplContext } from '../contexts';
import { useMobile } from '../hooks';

export const useMessageContent = () => {
    const { messages, loading, fullscreen, currentAppConfig, userDetails } = useChatContext();
    const { currentStep } = useCustomKplContext();

    const isMobile = useMobile();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [showScrollButton, setShowScrollButton] = useState(false);

    const showMessagesLoading = loading && messages.length === 0;
    const showWelcomeMessage = !loading && messages.length === 0;
    const showMessageTyping = loading && messages.length > 0;

    useEffect(() => {
        const handleScroll = () => {
            if (!containerRef.current) return;

            const isNotAtBottom =
                containerRef.current.scrollHeight -
                    (containerRef.current.scrollTop + containerRef.current.clientHeight) >
                1;

            setShowScrollButton(isNotAtBottom);
        };

        const container = containerRef.current;
        container?.addEventListener('scroll', handleScroll);

        handleScroll();

        return () => container?.removeEventListener('scroll', handleScroll);
    }, [messages, loading, currentStep]);

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    return {
        messages,
        containerRef,
        loading,
        showScrollButton,
        isMobile,
        fullscreen,
        currentAppConfig,
        userDetails,
        showMessagesLoading,
        showWelcomeMessage,
        showMessageTyping,
        scrollToBottom,
    };
};
