import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ChatType, ModelType, OperationType, PromptTemplateCategory } from '../../../../../__generated__/graphql';
import { useAdminPanelContext, useChatContext } from '../../../../../contexts';
import { ADD_KPL, GET_KPL_BY_KEY, UPDATE_KPL } from '../../../../../graphql';
import { useAllKPLs } from '../../../../../hooks';
import { toConstantCase } from '../../../../../utils';
import { ICurrentStep } from '../kpl-form';

export const useKPLFormContainer = (
    selectedKPLKey?: string,
    refetchKPL?: boolean,
    refetchData?: Function | undefined
) => {
    const [addKPLConfiguration] = useMutation(ADD_KPL);
    const [updateKPLConfiguration] = useMutation(UPDATE_KPL);
    const [getKplByKey] = useLazyQuery(GET_KPL_BY_KEY, { fetchPolicy: 'network-only' });

    const { doesKPLConfigExist } = useAllKPLs();
    const { kplFormData, setKPLFormData, resetKPLFormData } = useAdminPanelContext();
    const { userDetails, refetchApplicationConfigs } = useChatContext();
    const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
    const [currentStep, setCurrentStep] = useState<ICurrentStep>({
        step: 1,
        disabled: false,
    });
    const [validationErrorMsgs, setValidationErrorMsgs] = useState<any>();

    const errorMessages = {
        step1: {
            title: 'Please provide a title.',
            icon: 'Please select an icon.',
            shortDescription: 'Please provide a short description.',
            detailedDescription: 'Please enter a Information Icon Tooltip content.',
            chatType: 'Please choose a chat type.',
            criteria: 'Select at least one filtering criterion.',
            kplConfig: 'KPL key already exists for this title. Please use a different title.',
        },
        step2: {
            promptVariables: 'Ensure each prompt includes all necessary variables.',
        },
        step3: {},
    };

    // Validation logic for each step
    const validateCurrentStep = () => {
        // return true;
        switch (currentStep.step) {
            case 1:
                return (
                    kplFormData.title !== '' &&
                    kplFormData.title.length > 0 &&
                    kplFormData.icon !== '' &&
                    kplFormData.shortDescription !== '' &&
                    kplFormData.shortDescription.trim().replace(/\s+/g, '').length > 0 &&
                    kplFormData.detailedDescription !== '' &&
                    kplFormData.detailedDescription.trim().replace(/\s+/g, '').length > 0 &&
                    kplFormData.chatType !== null &&
                    Object.values(kplFormData.criteria).some(value => Boolean(value)) &&
                    Object.values(kplFormData.criteria).some(value => {
                        if (value !== null && typeof value === 'string') {
                            const isValid = value.trim().length > 0;
                            return isValid;
                        }
                        return false;
                    }) &&
                    (selectedKPLKey ? true : !doesKPLConfigExist(toConstantCase(kplFormData.title)))
                );
            case 2:
                return true;
            case 3:
                return true;
            default:
                return false;
        }
    };

    const validateMsgCurrentStep = () => {
        let errors: string[] = [];

        switch (currentStep.step) {
            case 1:
                if (kplFormData.title.trim() === '') errors.push(errorMessages.step1.title);
                if (
                    !selectedKPLKey &&
                    doesKPLConfigExist(toConstantCase(kplFormData.title)) &&
                    kplFormData.title.trim() !== ''
                )
                    errors.push(errorMessages.step1.kplConfig);
                if (kplFormData.icon.trim() === '') errors.push(errorMessages.step1.icon);
                if (kplFormData.shortDescription.trim() === '') errors.push(errorMessages.step1.shortDescription);
                if (kplFormData.detailedDescription.trim() === '') errors.push(errorMessages.step1.detailedDescription);
                if (kplFormData.chatType === null) errors.push(errorMessages.step1.chatType);
                if (
                    !Object.values(kplFormData.criteria).some(value => Boolean(value)) ||
                    !Object.values(kplFormData.criteria).some(value => {
                        if (value !== null && typeof value === 'string') {
                            const isValid = value.trim().length > 0;
                            return isValid;
                        }
                        return false;
                    })
                )
                    errors.push(errorMessages.step1.criteria);
                break;
            case 2:
                // No specific validation for step 2
                break;
            case 3:
                // No specific validation for step 3
                break;
            default:
                errors.push('Unknown step.');
                break;
        }

        return errors;
    };

    const handleSubmit = async () => {
        if (isCurrentStepValid && currentStep.step === 3) {
            try {
                const configKey = selectedKPLKey ? selectedKPLKey : toConstantCase(kplFormData.title);

                const input = {
                    ...kplFormData,
                    email: userDetails.email,
                    configKey,
                    chatType: (selectedKPLKey ? kplFormData.chatType : ChatType.Assistant) as ChatType,
                    enabled: selectedKPLKey ? kplFormData.enabled : true,
                    predefinedQuestions: kplFormData.predefinedQuestions.filter(question => question !== ''),
                    domains: kplFormData.domains,
                    users: kplFormData.users,
                    prompts: kplFormData.prompts,
                    autoGenerate: kplFormData.autoGenerate,
                };

                if (selectedKPLKey) {
                    await updateKPLConfiguration({ variables: { input } });
                    toast.success('KPL updated successfully');
                } else {
                    await addKPLConfiguration({ variables: { input } });
                    toast.success('KPL added successfully');
                }
            } catch (error) {
                console.log(error);
                toast.error(selectedKPLKey ? 'Failed to update KPL' : 'Failed to add KPL');
            } finally {
                resetKPLFormData();
                if (refetchData) refetchData();
                refetchApplicationConfigs();
                setCurrentStep({ step: 1, disabled: false });
            }
        }
    };

    useEffect(() => {
        setIsCurrentStepValid(validateCurrentStep());

        //validation error msg
        const validationErrors = validateMsgCurrentStep();
        setValidationErrorMsgs(validationErrors);
    }, [currentStep, kplFormData]);

    useEffect(() => {
        const fetchKPLByKey = async () => {
            if (refetchKPL && selectedKPLKey) {
                try {
                    const { data } = await getKplByKey({ variables: { configKey: selectedKPLKey } });

                    const predefinedQuestions = data?.getKPLByConfigKey?.guidelines?.map(
                        question => question?.guidelinesText || ''
                    ) || [''];

                    const operation =
                        Object.keys(data?.getKPLByConfigKey?.metadata?.filter || {})?.[0] === '$and'
                            ? OperationType.And
                            : OperationType.Or;

                    const filters = data?.getKPLByConfigKey?.metadata?.filter[`$${operation.toLowerCase()}`];

                    const criteria = filters.reduce((acc: any, filter: any) => {
                        const key = Object.keys(filter)[0];
                        const value = filter[key].$in.join(', ');
                        return { ...acc, [key]: value };
                    }, {});

                    setKPLFormData(kplFormData => ({
                        ...kplFormData,
                        title: data?.getKPLByConfigKey?.title || '',
                        icon: data?.getKPLByConfigKey?.icon || '',
                        shortDescription: data?.getKPLByConfigKey?.description || '',
                        detailedDescription: data?.getKPLByConfigKey?.metadata?.welcomeMessage || '',
                        chatType: data?.getKPLByConfigKey?.chatType || ChatType.Assistant,
                        criteria: criteria,
                        operation: operation,
                        prompts:
                            data?.getKPLByConfigKey?.prompts?.map(prompt => ({
                                model: prompt?.model || ModelType.OpenAi,
                                category: prompt?.category || PromptTemplateCategory.ChatPrompt,
                                prompt: prompt?.prompt || '',
                                variables: prompt?.variables || [],
                            })) || [],
                        predefinedQuestions: predefinedQuestions.length === 0 ? [''] : predefinedQuestions,
                        additionalSettings: {
                            noLLM: data?.getKPLByConfigKey?.metadata?.noLLM?.enabled || false,
                            webSearch: data?.getKPLByConfigKey?.metadata?.webSearch?.enabled || false,
                            dynamicPrompts: data?.getKPLByConfigKey?.metadata?.dynamicPrompts?.enabled || false,
                        },
                        users: data?.getKPLByConfigKey?.users?.map(user => String(user)) || [],
                        domains: data?.getKPLByConfigKey?.domains?.map(domain => String(domain)) || [],
                        enabled: data?.getKPLByConfigKey?.enabled || false,
                    }));
                } catch (error) {
                    console.log(error);
                }
            }
        };

        fetchKPLByKey();
    }, [selectedKPLKey, refetchKPL]);

    return {
        currentStep,
        isCurrentStepValid,
        setCurrentStep,
        resetKPLFormData,
        handleSubmit,
        validationErrorMsgs,
    };
};
