import { SearchInput } from '@kaya/kaya-ui-design-system-pb';
import { useEffect, useState } from 'react';
import { PromptTemplateCategory } from '../../../__generated__/graphql';
import { ALL_VALUE } from '../../../constants';
import { deConstantCase } from '../../../utils';

export interface ICategorySelectProps {
    onChange: Function;
    value?: string | null;
    hasAllOption?: boolean;
}

export const CategorySelect = ({ onChange, hasAllOption, value }: ICategorySelectProps) => {
    const categories = Object.values(PromptTemplateCategory).map(category => {
        return { key: category as PromptTemplateCategory, name: deConstantCase(category) };
    });
    const allCategories = categories.map(category => category.name);
    const CATEGORY_LIST = hasAllOption ? [ALL_VALUE, ...allCategories] : allCategories;

    const [currentCategory, setCurrentCategory] = useState<string>(
        value === null
            ? hasAllOption
                ? ALL_VALUE
                : categories[0].name
            : categories?.find(category => category.key === value)?.name || categories[0].name
    );

    useEffect(() => {
        if (value === ALL_VALUE) {
            setCurrentCategory(value);
        }

        if (value === null || value === undefined) {
            setCurrentCategory(hasAllOption ? ALL_VALUE : CATEGORY_LIST[0]);
        }
    }, [value]);

    return (
        <SearchInput
            value={currentCategory}
            onChange={(value: string) => {
                const name = value;
                const key = categories?.find(category => category.name === value)?.key as PromptTemplateCategory;
                setCurrentCategory(name);
                onChange({ name: name, key: value === ALL_VALUE ? null : key });
            }}
            placeholder="Select Model"
            view="default"
            wrapperClassName="w-full"
            items={CATEGORY_LIST}
            onKeyDown={e => e.preventDefault()}
        />
    );
};
