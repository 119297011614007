import { Avatar, Button, Dropdown } from '@kaya/kaya-ui-design-system-pb';
import { useChatContext, useSideBarContext } from '../../../../contexts';
import { KeycloakService } from '../../../../services';
import { useMobile } from '../../../../hooks';
import { ButtonWithTooltip } from '../../buttons';

export const SidebarAvatarSection = () => {
    const { userDetails } = useChatContext();
    const { isMenuCollapsed } = useSideBarContext();
    const isMobile = useMobile();

    const LogoutButton: React.FC = () => {
        return (
            <ButtonWithTooltip showTooltip={true} title="Logout">
                <div className="px-4 sm:px-0">
                    <Button
                        iconBefore="ri-logout-box-line"
                        className="h-[40px] w-[40px] hover:bg-N-200 rounded-[8px]"
                        onClick={() => {
                            KeycloakService.doLogout({});
                        }}
                    />
                </div>
            </ButtonWithTooltip>
        );
    };

    return isMenuCollapsed || isMobile ? (
        <div className="flex sm:justify-between justify-around sm:ms-0 ms-3">
            <div className="sidebar_avatar flex items-center justify-start cursor-pointer gap-x-3 overflow-hidden w-[calc(100%-72px)]">
                <Avatar
                    size="md"
                    type="text"
                    textLabel={userDetails.name
                        .split(' ')
                        .map(name => name.charAt(0))
                        .join('')}
                    supportIconStatus="online"
                    hasSupportIcon
                    imageURL={userDetails.imageUrl}
                />
                <div className="flex flex-col gap-y-1 w-[calc(100%-36px)] overflow-hidden">
                    <p className="text-N-900 font-500 text-sm whitespace-nowrap truncate">{userDetails.name}</p>
                    <p className="text-N-600 font-400 text-xs whitespace-nowrap truncate">{userDetails.email}</p>
                </div>
            </div>
            <LogoutButton />
        </div>
    ) : (
        <LogoutButton />
    );
};
