import { SpinLoader } from '@kaya/kaya-ui-design-system-pb';
import { MessageAuthor, MessageType } from '../../../__generated__/graphql';
import { SYSTEM_TEMP_ID } from '../../../constants';
import { useMessageContent, useMessagesRead, useMessenger } from '../../../hooks';
import { ChatMessage, MessageTyping, ScrollableWrapper, WelcomeMessage } from '../../common';
import { ScrollDownButton } from '../../common/buttons';
import { CustomWelcomeMessage } from '../../common/custom-welcom-message';

const MessageContent = () => {
    const {
        messages,
        containerRef,
        showScrollButton,
        showMessagesLoading,
        showWelcomeMessage,
        showMessageTyping,
        scrollToBottom,
        currentAppConfig,
    } = useMessageContent();

    const { submitMessage } = useMessenger();
    useMessagesRead(messages);

    const showCustomWelcomeMessage = currentAppConfig?.metadata.customWelcomeMessage?.enabled ? true : false;

    return (
        <ScrollableWrapper ref={containerRef}>
            <div className="sm:pb-[30%]">
                {showMessagesLoading && (
                    <div className="flex justify-center items-center h-full">
                        <SpinLoader />
                    </div>
                )}
                {/* Remove this conditional check once the Fast Food and Beverage Industry KPL is fully implemented in the backend. */}
                {showCustomWelcomeMessage ? (
                    <CustomWelcomeMessage />
                ) : (
                    <>{showWelcomeMessage && <WelcomeMessage submitMessage={submitMessage} />}</>
                )}

                {messages?.map(({ id, content, author, feedback, processing, time, type, sources, elements }, i) => (
                    <div className="my-2" key={i}>
                        {content && (
                            <ChatMessage
                                key={i}
                                id={id}
                                index={i + 1}
                                feedback={feedback}
                                text={content}
                                isUser={author === MessageAuthor.User}
                                disableCopy={
                                    [MessageType.SmeResponse, MessageType.LearningMaterial].includes(type!) ||
                                    id.includes(SYSTEM_TEMP_ID) ||
                                    Boolean(processing)
                                }
                                time={time}
                                type={type}
                                sources={sources}
                                submitMessage={submitMessage}
                                enableCallReports={false}
                                enableTestCaseAnalysis={false}
                                elements={elements}
                            />
                        )}
                    </div>
                ))}

                {showMessageTyping && <MessageTyping />}
                {showScrollButton && <ScrollDownButton scrollToBottom={scrollToBottom} />}
            </div>
        </ScrollableWrapper>
    );
};

export { MessageContent };
